.home {
    background: white;

    h3 {
        margin: 0;
    }

    .page-section {}

    .section-1 {
        background-image: url('../../assets/bg-sky.png');
        background: #F1EDFC;
        padding-bottom: 140px;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .section-2 {
        z-index: 9999;
        position: relative;
        padding-bottom: 70px;
    }

    .max-w {
        max-width: 290px;
    }

    .section-3 {
        background-color: #F1EDFC;
        padding: 50px;
        padding-left: 33px;
        padding-right: 0px;
    }

    .section-5 {
        padding-top: 70px;
        text-align: center;
        padding-bottom: 70px;
    }

    .section-6 {
        padding: 30px;
    }

    .container-section-2,
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .how-it-works {
        text-align: center;
        text-transform: uppercase;
        color: #5A6A75;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
    }

    .section-5 h3 {
        color: #191F23;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
    }

    .section-4 {
        padding-top: 60px;
        padding-left: 30px;
    }

    .section-4>p {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #001B52;
        margin-bottom: 68px;
    }

    .section-4 h3 {
        font-weight: 700;
        font-size: 32px;
        line-height: 47px;
        color: #001B52;
        width: 227px;
        height: 94px;
        margin-bottom: 15px;
    }

    .header-logo {
        width: 60.68px;
        margin-top: 41px;
        margin-bottom: 84px;
    }

    .main-title {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 111.3%;
        /* or 27px */

        text-align: center;

        /* Azul marinho */

        color: #001B52;
        margin-bottom: 15px;
    }

    .main-paragraph {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #001B52;
        max-width: 280px;
    }

    .home-button {
        text-decoration: none;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        width: 215px;
        height: 48px;
        background: #AF4AFF;
        border-radius: 12px;
        margin-top: 32px;
        margin-bottom: 32px;
    }

    .moon-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: -90px;
    }

    .container-section-2 {
        padding-top: 60px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .about-text {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* or 171% */

        text-align: center;

        /* Text/Secondary */

        color: #5A6A75;

    }

    .contact-link {
        text-decoration: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #E08E45;
        margin-top: 30px;
        display: flex;
        align-items: center;
    }

    .profile-name {
        font-weight: 700;
        font-size: 24px;
        line-height: 40px;
        color: #001B52;
        margin-top: 30px;
    }

    .profile-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 40px;
        color: #001B52;
        margin-bottom: 15px;
    }

    .profile-picture {
        border-radius: 50%;
    }

    .card-numbers {
        background-color: #FFFFFF;
        box-shadow: 0px 3.53623px 24.7536px rgba(175, 74, 255, 0.13);
        border-radius: 7.07246px;
        padding: 20px;
        margin-right: 12px;
        min-width: 250px;
        height: 250px;
        width: 254px;
        height: 219px;
    }

    .card-score {
        color: #AF4AFF;
        font-weight: 700;
        font-size: 60px;
        line-height: 57px;
        margin-bottom: 15px;
    }

    .image-group-section-2 {
        position: relative;
    }

    .logo-yellow {
        position: absolute;
        left: -15px;
        bottom: -5px;
    }

    .card-emphasis {
        font-weight: 600;
        font-size: 14.1449px;
        line-height: 25px;
        margin-bottom: 20px;
    }

    .card-description {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12.3768px;
        line-height: 21px;
        color: #5A6A75;
    }

    .container-scroll {
        display: flex;
        overflow-x: scroll;
        padding: 10px;
        margin-left: -10px;

        &::-webkit-scrollbar {
            display: none;
            /* Safari and Chrome */
        }
    }

    .title-section-3 {
        font-weight: 600;
        font-size: 32px;
        line-height: 111.3%;
        color: #3E0051;
    }

    .header-section-3 {
        display: flex;
        align-items: center;
        padding-bottom: 30px;
        padding-right: 20px;
    }

    .saturn-picture {
        min-width: 66px;
        min-height: 36.75px;
        max-height: 36px;
    }

    .testimonial-sophia {
        background-color: #001B52;
        color: #FFFFFF
    }

    .testimonial-marcia {
        background-color: #F1EDFC;
        color: #001B52;
    }

    .container-signature {
        margin-left: 22px;
        display: flex;
        flex-direction: column;
    }

    .testimonial-author {
        font-weight: 700;
        font-size: 16px;
    }

    .card-testimonial {
        padding: 20px;
        margin-right: 12px;
        min-width: 65vw;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    }

    .container-author {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .testimonial-content {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 21px;

    }

    .testimonial-date {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
    }

    .step-number {
        position: absolute;
        color: #FEFCFF;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
    }

    .step-title {
        color: #191F23;
        font-weight: bolder;
        font-size: 14px;
        line-height: 24px;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 10px;
    }

    .step-container {
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .step-content {
        color: #5A6A75;
        width: 60%;
    }

    .testimonials-scroll {
        display: flex;
        overflow-x: scroll;
        padding: 10px;

        &::-webkit-scrollbar {
            display: none;
            /* Safari and Chrome */
        }
    }

    .yellow-container {
        background: #EEE52F;
        border-radius: 16px;
        padding: 33px;
        padding-top: 40px;
        padding-bottom: 40px;
        font-weight: 400;
        font-size: 14px;
        line-height: 147.3%;
        color: #3E0051;
        position: relative;
        margin-bottom: 30px;
    }

    .star-1 {
        position: absolute;
        right: 7px;
        top: -33px;
    }

    .star-2 {
        position: absolute;
        right: 47px;
        top: -10px;
    }

    .yellow-container h3 {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        /* or 147% */


        /* Azul marinho */

        color: #001B52;
    }
}

.step-profile {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}


@media (max-width: 575.98px) {}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {}

// X-Large devices (large desktops, less than 1400px)
@media (min-width: 1024.98px) {
    .home .main-title {


        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 56px;
        line-height: 111.3%;
        /* or 62px */

        text-align: center;
        margin-bottom: 30px;
    }

    .container-desk {
        max-width: 1225px;
        margin-left: auto;
        margin-right: auto;
    }

    .card-testimonial {
        width: 50% !important;
        min-width: 50% !important;
    }

    .mt-1 {}

    .mt-2 {
        margin-top: 90px;
    }

    .mt-3 {
        margin-top: 175px;
    }

    .mt-4 {
        margin-top: 270px;
    }
}