body {
    background-image: url('../../assets/bg-dark.png');
    background-repeat: no-repeat;
    background-size: cover;
}

p {
    margin: 0;
}

* {
    margin: 0;
}

a {
    text-decoration: none;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 600px;
}

header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    position: relative;
}

.btn-back {
    position: absolute;
    left: 10px;
}

.section-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.steps {
    margin-top: 80px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    /* Limão siciliano */

    color: #EEE52F;
    margin-bottom: 16px;

}

.title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    /* or 133% */

    text-align: center;

    /* Branco */

    color: #FEFCFF;
    margin-bottom: 16px;

}

.desc {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */

    text-align: center;

    /* Branco */

    color: #FEFCFF;
    margin-bottom: 22px
}

.list-rock {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 600px;
}

.box-rock {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 24px 24px 16px;
    gap: 16px;
    width: 100%;
    background: rgba(175, 74, 255, 0.16);
    /* Púrpura CTA */

    border: 1px solid #AF4AFF;
    border-radius: 26px;
    margin-bottom: 20px;
}

.rock-img {
    width: 60px;
}

.col {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.title-rock {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    /* or 229% */

    display: flex;
    align-items: center;

    /* Branco */

    color: #FEFCFF;
}

.desc-rock {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */


    /* Branco */

    color: #FEFCFF;
}

.arrow-next {
    margin-left: auto;
}