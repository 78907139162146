.loading {
    display: flex;
    flex-direction: column;
    margin-top: 150px;
    align-items: center;
    margin-bottom: 180px;

    .title {
        margin-bottom: 20px;
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 111.3%;
        /* or 27px */

        text-align: center;

        /* Branco */

        color: #FEFCFF;

    }

    .desc {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* or 171% */

        text-align: center;

        /* Branco */

        color: #FEFCFF;
        margin-top: 24px;
    }

    .logo-loading {
        width: 104px;
        height: 104px;
    }
}

#root {
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.3s;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}