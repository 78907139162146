.footer {
    width: 100%;
    background: #3E0051;
    display: flex;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 50px;
    margin-top: auto;
}

.logo-footer {
    width: 57px;
    height: 38px;
    margin-left: 20px;
}

.col-footer {
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    padding-left: 30px;
}

.text-footer {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 111.3%;
    /* or 16px */


    /* Branco */

    color: #FEFCFF;
}

.link-footer {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
    /* Limão siciliano */

    color: #EEE52F;

}

small {
    margin-top: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 111.3%;
    /* or 16px */


    /* Branco */

    color: #FEFCFF;
}

section {
    margin-bottom: 70px;
}

* {
    font-family: 'Work Sans', sans-serif;
}