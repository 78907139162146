body {
    background-image: url('../../assets/bg-dark.png');
    background-repeat: no-repeat;
    background-size: cover;
}

p {
    margin: 0;
}

* {
    margin: 0;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 600px;
}

header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    position: relative;
}

.btn-back {
    position: absolute;
    left: 10px;
}

.section-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.steps {
    margin-top: 80px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    /* Limão siciliano */

    color: #EEE52F;
    margin-bottom: 16px;

}

.title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    /* or 133% */

    text-align: center;

    /* Branco */

    color: #FEFCFF;
    margin-bottom: 16px;

}

.desc {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */

    text-align: center;

    /* Branco */

    color: #FEFCFF;
    margin-bottom: 22px
}

a.tablinks {
    text-decoration: none;
    color: black;
    font-style: normal;
    font-weight: 600;
    font-size: 16.2595px;
    line-height: 22px;
    /* identical to box height, or 133% */


    /* Limão siciliano */

    color: #FEFCFF;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 0.451654px solid #F1EDFC;

    &.selected-tab {
        border-bottom: 1px solid #EEE52F;
        color: #EEE52F;
    }
}

.tab1 {
    margin-right: 1px;
}

.tab2 {
    margin-left: -5px;
}



.desc-step {
    padding-left: 30px;
    padding-right: 30px;
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    /* or 175% */

    text-align: center;

    /* Branco */

    color: #FEFCFF;

    opacity: 0.72;
    margin-top: 30px;
    margin-bottom: 20px;
}

.card {
    filter: drop-shadow(4px 6px 10px #789DE9);
    width: 156px;
    height: 288px;
}

.swiper-container {
    width: 360px;
    height: 325px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.swiper-slide img {
    width: 156px;
    height: 288px;
}

.swiper-button-prev,
.swiper-button-next {
    display: none;
}

.content-card {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 600px;
}

.tag {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 17px;
    gap: 11.33px;

    width: 147px;
    height: 32px;

    /* Laranja */

    background: #E08E45;
    border-radius: 34px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    /* or 183% */


    /* Branco */

    color: #FEFCFF;

}

.title-card {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 111.3%;
    margin-bottom: 20px;
    margin-top: 5px;
    /* or 36px */


    /* Branco */

    color: #FEFCFF;
}

.desc-card {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */

    white-space: pre-wrap;
    /* Branco */

    color: #FEFCFF;
}

.card-tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
}

.tag-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 17px;
    gap: 11.33px;
    height: 32px;
    margin-right: 15px;
    margin-bottom: 15px;
    /* Branco */

    border: 1px solid #FEFCFF;
    border-radius: 34px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    /* or 183% */


    /* Branco */

    color: #FEFCFF;
}

.img-rock-box {
    width: 275px;
    margin-top: 20px;
}

.finish-title {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 111.3%;
    /* or 45px */


    /* Branco */

    color: #FEFCFF;
    margin-top: 20px;
    margin-bottom: 20px;
}

.finish-desc {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */


    /* Branco */

    color: #FEFCFF;
}

.how-feel-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */


    /* Branco */

    color: #FEFCFF;
}

.feedback {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50px;
    margin-right: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */

    text-align: center;

    /* Branco */

    color: #FEFCFF;

}

.selected {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */

    text-align: center;

    /* Limão siciliano */

    color: #EEE52F;
}

.how-feel-text {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    /* or 175% */


    /* Branco */

    color: #FEFCFF;
}

.feedback-selected {
    background: #EEE52F;
    color: #3E0051;
}

.receive-email-text {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    /* or 133% */


    /* Branco */

    color: #FEFCFF;
    margin-top: 30px;
    margin-bottom: 30px;
}

.btn-share {
    width: 100%;
    height: 48px;

    /* Púrpura CTA */

    background: #AF4AFF;
    /* Púrpura CTA */

    border: 1px solid #AF4AFF;
    border-radius: 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-align: center;

    /* Text/Inverted */

    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin-bottom: 30px;
}

.swiper-pagination {
    bottom: 0px;
}

.swiper-pagination-bullet {
    background-color: #B4AFC4;
    opacity: 1;

}

.swiper-pagination-bullet-active {
    background-color: #EEE52F;
}

.primeira {
    background: #AF4AFF;

}

.segunda {
    background: #EEE52F;
    color: #3E0051;

}

.terceira {
    background: #E08E45;
}