body {
    background-image: url('../../assets/bg-dark.png');
    background-repeat: no-repeat;
    background-size: cover;
}

p {
    margin: 0;
}

* {
    margin: 0;
}

.container-cards {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    position: relative;
}

.btn-back {
    position: absolute;
    left: 10px;
}

.section-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.steps {
    margin-top: 80px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    /* Limão siciliano */

    color: #EEE52F;
    margin-bottom: 16px;

}

.title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    /* or 133% */

    text-align: center;

    /* Branco */

    color: #FEFCFF;
    margin-bottom: 16px;

}

.desc {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */

    text-align: center;

    /* Branco */

    color: #FEFCFF;
    margin-bottom: 22px
}

@keyframes shake-left {

    10%,
    90% {
        transform: translate3d(-40px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(40px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-40px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(40px, 0, 0);
    }

    10%,
    90% {
        transform: translate3d(0, -40px, 0);
    }

    20%,
    80% {
        transform: translate3d(0, 40px, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(0, -40px, 0);
    }

    40%,
    60% {
        transform: translate3d(0, 40px, 0);
    }

}

@keyframes shake-right {

    10%,
    90% {
        transform: translate3d(40px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(-40px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(40px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(-40px, 0, 0);
    }
}

.shake-left {
    // animation: shake-left 4s
}

.shake-right {
    //animation: shake-right 4s
}


.list-card {
    display: flex;
    flex-wrap: wrap;
    padding-left: 70px;

}

.down {
    margin-top: -125px;
}

.center {
    margin-left: 18px;
}

@keyframes shake-cards {

    0%,
    25% {
        margin-left: -110px;
    }

    25%,
    50% {
        margin-left: -70px;
    }

    50%,
    75% {
        margin-left: -110px;
    }

    75%,
    100% {
        margin-left: -70px;
    }

}

@keyframes showCard {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }

}


.card-img {
    // filter: drop-shadow(4px 6px 10px #789DE9);
    box-shadow: 0px 0px 15px #789de9;
    // box-shadow:  4px 6px 10px #789DE9,
    //                 4px 6px 10px #789DE9,
    //                 4px 6px 10px #789DE9,
    //                 4px 6px 10px #789DE9;
    width: 105px;
    height: 194px;
    margin-left: -70px;

    &.animation {
        animation: shake-cards ease-out 2s;
    }


}

.shake {
    margin-top: 80px;
}

.current-card {
    position: absolute;
    top: 50%;
    z-index: 1;
    display: flex;
    justify-content: center;
    width: 100%;
}

@keyframes animate-down {

    from {
        margin: 0;
        width: 166.68px;
        height: 310.59px;
        opacity: 1;
    }

    to {
        opacity: 0;
        margin-top: 400px;
        width: 73px;
        height: 133px;
        transition: all;
    }
}

.current {
    margin: 0;
    width: 166.68px;
    height: 310.59px;
    border-radius: 10px;
    transition: all 3s;

    &.animate-down {
        transition: all;
        animation: animate-down 2s;
    }
}

.cards {
    opacity: 0.2;
    //min-height: 350px;
}

.selected-cards {
    display: flex;
    margin-top: 30px;
    justify-content: center;
    transition: all 2s;

    &.animation-small {
        transition: all 2s;
        margin-left: auto;
    }
}

.small {
    width: 73px;
    height: 133px;
    margin: 0;
    border-radius: 10px;
    margin-right: 15px;
    margin-left: 15px;
    transition: all 1s;
    animation: showCard 0.3s;

    &.animation-small {
        margin-left: -60px;
    }
}

.green {
    background: rgba(0, 27, 82, 0.36);
    /* Green 1 */
    border: 1px solid #219653;
    border-radius: 26px;
    margin-top: 30px;
    max-width: 340px;
}

.way {
    margin-bottom: 10px !important;
    text-align: left !important;
}

.box-way-cards {
    //display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 30px;
}

.selected-card-way {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}