body {
    background-image: url('../../assets/bg-dark.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}

p {
    margin: 0;
}

* {
    margin: 0;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 600px;
}

header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    position: relative;
}

.btn-back {
    position: absolute;
    left: 10px;
}

.section-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.steps {
    margin-top: 80px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    /* Limão siciliano */

    color: #EEE52F;
    margin-bottom: 16px;

}

.title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    /* or 133% */

    text-align: center;

    /* Branco */

    color: #FEFCFF;
    margin-bottom: 16px;

}

.desc {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */

    text-align: center;

    /* Branco */

    color: #FEFCFF;
    margin-bottom: 22px
}


.green {
    background: rgba(0, 27, 82, 0.36);
    /* Green 1 */
    border: 1px solid #219653;
    border-radius: 26px;
    margin-top: 30px;
    max-width: 340px;
}

.box-way {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    width: 85%;
    max-width: 600px;
}

textarea {
    margin: 0;
    padding: 12px;
    gap: 4px;
    /* Background/Primary */

    background: #FFFFFF;
    /* Separator/Default */

    border: 1px solid #7A93A3;
    border-radius: 12px;
}

.box-way-desc {
    text-align: left;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 10px;
}

.btn-next {
    /* Púrpura CTA */

    background: #AF4AFF;
    /* Púrpura CTA */

    border: 1px solid #AF4AFF;
    border-radius: 12px;
    display: flex;
    width: 90%;
    max-width: 600px;
    color: #FEFCFF;
    max-width: 400px;
    height: 48px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.img-next-btn {
    margin-left: 15px;
}